// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';

@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.nav-dropdown-toggle {
    background-color: #171717 !important;
}

.outside_logo_container_holder {
    // background-color: #b7b5b5;
    background-color: #003d5d;
}

.copy_clipboard {
    position: relative;

    .form-control {
        padding-right: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media (max-width: 575px) {
            padding-right: 56px;
        }
    }

    .btn {
        position: absolute;
        right: 1px;
        margin-top: -35px;
        outline: 0 !important;
        border-left: #dcdee1 1px solid;
        background-color: #e4e5e6;
        padding: 0.36rem 0.75rem;
        border-bottom-left-radius: 0px !important;
        border-top-left-radius: 0px !important;

        @media (max-width: 575px) {
            span {
                display: none;
            }
        }
    }
}

select#crm_protocol {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-right: 1px solid #e4e5e6;
}

span#basic-addon2 {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
}


.input-group-text {
    background-color: #e4e5e6;
}

// .store-logo-whl {
//     position: relative;
//     border: #eee 1px solid;
// }
// .store-logo {
//     width: 100%;
//     display: flex;
//     margin: auto;
//     justify-content: center;
//     height: 200px;
//     align-items: center;
//     border-bottom: #eee 1px solid;
//     margin: 0 0 13px 0;
// }
// .store-logo-txt {
//     margin: 10px 0 20px 0;
//     position: relative;
// }
.disabledCursor {
    cursor: default;
}

// .sticky-action-body{
//     //height: 75vh;
//     height: 52vh;
//     align-content: center;
//     @media (max-width: 767px){
//         height: 100%;
//         padding: 20px 0;
//     }
// }
.store_user_details {
    padding: 3em;
    margin: 1em 0;
    border: 1px solid #ddd;

}

.empty_user_details {
    text-align: center;

    i {
        font-size: 40px;
        margin-bottom: 15px;
        color: #787878;
    }

    p {
        font-size: 20px;
        font-weight: 600;
        margin: 0 0 20px;
    }

    button {
        display: flex;
        justify-content: center;
        margin: 0 auto;

        i {
            font-size: 20px;
            margin: 1px 0 0 0;
            color: #fff;

            @media (max-width: 575px) {
                font-size: 15px;
            }
        }
    }
}

.swal2-cancel {
    order: 1;
}

.swal2-confirm {
    order: 2;
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.inner_pages {
    table {
        tr {
            th {
                white-space: nowrap;
            }
        }
    }
}

.accept_header_text {
    font-size: 19px;
    text-align: center;
}

.autosuggest_modal_close {
    position: absolute;
    left: auto;
    right: 17px;
}

/* removed to fix the card header content alignment
.card-header {
    padding-left: 11px;
}
*/

.custom-badge {
    position: absolute;
    right: -8px;
    top: -8px;
    padding: 0.4em;
    height: 20px;
    width: 20px;
}

.fa-long-arrow-left {
    color: grey;
}

.custom-ban-icon {
    font-size: 20px;
    color: red;
}

.password-toggle {
    position: relative;

    .form-control {
        padding-right: 80px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @media (max-width: 575px) {
            padding-right: 56px;
        }
    }

    .pwd-view-icon {
        position: absolute;
        right: 0px;
        margin-top: -35px;
        outline: 0 !important;
        border-left: #dcdee1 1px solid;
        background-color: #e4e5e6;
        // padding: 0.3rem 0.5rem;
        height: 35px;
        width: 33px;
        justify-content: center;
        align-items: center;
        display: flex;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;

        @media (max-width: 575px) {
            span {
                display: none;
            }
        }

        .fa {
            cursor: pointer;
        }
    }

    .login-pwd-view-icon {
        position: inherit;
        right: 1px;
        margin-top: 0px;
        outline: 0 !important;
        border-left: #dcdee1 1px solid;
        background-color: #e4e5e6;
        padding: 0.3rem 0.75rem;
        height: 35px;
        width: 33px;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;

        @media (max-width: 575px) {
            span {
                display: none;
            }
        }

        .fa {
            cursor: pointer;
        }
    }
}

@media (max-width: 480px) {
    .outer_container h1 {
        font-size: 1.5rem;
    }
}

.nav-link {
    span {
        color: #171717;
    }
}

.linkuser_img {
    height: 31px;
    width: 31px;
    border-radius: 50%;
}

.linkuser_img img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

table {
    tr {
        td {
            white-space: nowrap;
        }
    }
}

.app-header .nav-item {
    min-width: 39px;
    text-align: left;
}

.app-header .nav-item>a .avatar.nav-link>img,
.app-header .nav-item .nav-link>.img-avatar {
    height: 40px;
    margin: 0 10px;
    width: 40px;
    object-fit: cover;
}

@media (max-width: 575px) {

    .inner_pages .nav-tabs .nav-item {
        margin-bottom: -1px;
        width: 100%;
        margin-bottom: 5px;
    }

    .inner_pages .nav-tabs .nav-link {
        color: #73818f;
        width: 100%;
    }

    .app-header .nav-item>a {
        display: flex;
        font-size: 11px;
        align-items: center;
        flex-direction: column-reverse;
    }



    .app-header .nav-item>a .avatar.nav-link>img,
    .app-header .nav-item .nav-link>.img-avatar {
        height: 22px;
        width: 22px;
    }

    .main .container-fluid {
        padding: 0 12px;
    }
}

.custom_dropdown .dropdown-item:active {
    background-color: #fff !important;
    color: #000 !important;
    outline: none !important;
}

.thumbnail {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-top: -30px;

    img {
        width: 100%;
        height: 100%;
    }
}

.personalInfo {
    font-weight: bold;
    padding-right: 20px;
}

.labelBold {
    font-weight: bold;
}

.img-wrap {
    position: relative;
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
}

.avatar-upload {
    position: relative;
    max-width: 175px;
    margin: -10px auto;
    padding-top: 10px;
    padding-bottom: 10px;
}

.avatar-upload .avatar-edit {
    position: absolute;
    right: 19px;
    z-index: 1;
    bottom: 7px;

    @media (max-width: 575px) {
        right: 28px;
    }
}

.avatar-upload .avatar-edit input {
    display: none;
}

.avatar-upload .avatar-edit input+label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #ffffff;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;

    @media (max-width: 575px) {
        width: 30px;
        height: 30px;
    }

}

.avatar-upload .avatar-edit input+label:hover {
    background: #f1f1f1;
    border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input+label:after {
    content: "\f030";
    font-family: "FontAwesome";
    color: #757575;
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;

    @media (max-width: 575px) {
        top: 5px;
    }
}

.avatar-upload .avatar-preview {
    margin: auto;
    width: 170px;
    height: 170px;
    position: relative;
    border-radius: 100%;
    border: 6px solid #f8f8f8;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

    @media (max-width: 575px) {
        width: 90px;
        height: 90px;
        border: 3px;
    }
}

.avatar-upload .avatar-preview>div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.bold {
    font-weight: bold;
}

.store_select img {
    max-width: 350px;
}

.store_select_inner span {
    height: 40px;
    width: 40px;
    background-color: #005aff;
    border-radius: 50px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
}

.store_select_inner p {
    font-size: 16px;
    font-weight: 500;
    color: #000;
}

.store_select_icon {
    color: #000;
    font-size: 18px;
    font-weight: 600;
}

.store_list_outer {
    min-height: 100vh !important;
}

.selected-date-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.selected-date-pill {
    height: 34px;
    align-items: center;
    display: flex;
    padding: 0px 15px;
}

.customDisableClass {
    opacity: 0.65;
}

.prd_img img {
    width: 40px;
    height: 40px;
    object-fit: cover;
}

.modal_overlay {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 9999;
    left: 0;
    top: 0;
    bottom:0;
    background: rgba(0, 0, 0, 0.4);
}

.spinner_body {
    text-align: center;
    position: absolute;
    top: 35%;
    left: 15%;
    right: 15%;
    width: 110px;
    height: 100px;
    z-index: 999;
    margin: auto;
}

body {
    position: relative;
}

.dark-blue {
    background: #1f1498 !important;
    background: linear-gradient(45deg, #321fdb, #1f1498) !important;
    border-color: #1f1498 !important;
}

@media (max-width: 1200px) {

    .lastLoggedInUser tbody tr td:last-child,
    .lastLoggedInUser thead tr th:last-child {
        display: none;
    }
}

table.lastLoggedInUser tr td {
    white-space: unset;
}

.lastLoggedInUser .user-dtl-text {
    width: 90%;

    @media (max-width: 300px) {
        max-width: 148px;
        overflow-wrap: break-word;
        word-wrap: break-word;
    }
}

.ip_responsive {
    display: none;

    @media (max-width: 1200px) {
        display: block;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 56px;
    height: 23px;
    margin-right: 10px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.dark-blue {
    background: #1f1498 !important;
    background: linear-gradient(45deg, #321fdb, #1f1498) !important;
    border-color: #1f1498 !important;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 250px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    // left: 50%;
    margin-left: -60px;
    right: -117px;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

.minHeight {
    min-height: 250px;
}

// .maxActivityHeight{
//     min-height: 538px;
// }

.chartMinHeight {
    min-height: 538px;
}
.disableDiv{
    pointer-events: none;
    opacity: 0.4;
}
.minHeight .loader_body {
    text-align: center;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
}

@media (max-width: 991.98px) {
    .client-login .app-body {
        margin-top: 106px;
    }

    .client-login .header_mobile {
        display: block !important;
        background-color: #fff;
        position: fixed;
        text-align: center;
        width: 100%;
        z-index: 999;
        top: 55px;
        padding: 10px 0;
        border-bottom: #ccc 1px solid;
    }

    .header_mobile {
        display: none !important;
    }
}

@media (max-width:390px) {
    .store_name_wrap {
        white-space: nowrap;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.responsiveLogo {
    // width: 318px;
    // margin-bottom: 10px !important;
    background-color: #003d5d;
    width: 100%;
    text-align: center;
    padding: 15px 0;
}

.no-Show {
    display: none;
}

//Start of new design for changing brand logo
.navbar-brand {
    background-color: #003d5d !important;
}

.navbar-toggler {
    background-color: #003d5d;
    margin-right: 15px;
    border-radius: 0;
    min-width: 45px !important;
    height: 55px;
    outline: 0 !important;
}

.navbar-toggler-icon {
    filter: brightness(0) invert(1);
}

.btn-primary,
.btn-info {
    background-color: #003d5d;
}

.btn-primary:hover,
.btn-info:hover {
    background-color: #036599;
}

.btn-link {
    color: #003d5d;
}

@media (max-width:991px) {
    .app-header {
        background-color: #003d5d;
    }

    .navbar-toggler,
    .navbar-brand {
        background-color: transparent !important;
    }

    .right-nav-name {
        color: #ffffff !important;
    }
}

//End of new design for changing brand logo
.addressValidationError {
    color: #ff8305 !important;
    margin-top: -1px;
}

.addressValidationWarning {
    color: #ff8305 !important;
}
// .addressValidationPending{
//     // color:#003d5d !important;
// }

// .scrollable-height {
//     // max-height: 500px;
//     // overflow-y: auto;
//     table-layout: fixed;
//     width: 100%;
// }

// .scrollable-height tbody {
//     max-height: 500px;
//     overflow-y: auto;
//     display: block;
// }

// /* width */
// .scrollable-height tbody::-webkit-scrollbar {
//   width: 5px;
// }

// /* Track */
// .scrollable-height tbody::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 5px grey;
//   border-radius: 10px;
// }

// /* Handle */
// .scrollable-height tbody::-webkit-scrollbar-thumb {
//   background: #063f5d;
//   border-radius: 10px;
// }

// /* Handle on hover */
// .scrollable-height tbody::-webkit-scrollbar-thumb:hover {
//   background: #03354f;
// }

.scrollable-height .table-responsive {
    height: 500px;
    overflow-y: auto;
    border-collapse: collapse;
    overflow-x: hidden;
}

.scrollable-height .table-responsive thead th {
    position: sticky;
    top: 0px;
    background: #ffffff;
    height: 20px;
    border-bottom: none;
    border-top: none;
    box-shadow: inset 0 1px 0 #ccc, inset 0 -2px 0 #ccc;
    background-clip: padding-box;
}


.scrollable-height .table-responsive::-webkit-scrollbar   {
    width: 5px;
}


.scrollable-height .table-responsive::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}


.scrollable-height .table-responsive::-webkit-scrollbar-thumb {
    background: #063f5d;
    border-radius: 10px;
}


/* width */
.scrollable-height::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.scrollable-height::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.scrollable-height::-webkit-scrollbar-thumb {
    background: #063f5d;
    border-radius: 10px;
}

/* Handle on hover */
.scrollable-height::-webkit-scrollbar-thumb:hover {
    background: #03354f;
}

.notification-scrollable-height {
    max-height: 350px;
    overflow-y: auto;
}


.activityLogLatest td {
    white-space: unset;
}

// .activityLogLatest thead th {
//     position: sticky;
//     top: 0;
// }

.activityLogLatest .user-dtl-text {
    width: 90%;
}

button.btn.refreshCount {
    padding: 1px 8px 0 0;
    color: #fff;
}

button.btn.refreshCount:focus {
    box-shadow: none;
    border: none;
}

.spinnerCount {
    margin: 5px 6px 0 0;
}

.spinnerCountStore {
    margin: 1px 6px 0 0;
}

.crm-icon img {
    width: 25px;
    height: 25px;
    align-items: center;
}

.crm-icon {
    width: 50px;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 2px 0 -10px;
}

.scrollable-height .table-responsive {
    height: 500px;
    overflow-y: auto;
    border-collapse: collapse;
}

.scrollable-height .table-responsive thead th {
    position: sticky;
    top: 0px;
    background: #ffffff;
    height: 20px;
    border-bottom: none;
    border-top: none;
    box-shadow: inset 0 1px 0 #ccc, inset 0 -2px 0 #ccc;
    background-clip: padding-box;
}


.scrollable-height .table-responsive::-webkit-scrollbar {
    width: 5px;
}


.scrollable-height .table-responsive::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}


.scrollable-height .table-responsive::-webkit-scrollbar-thumb {
    background: #063f5d;
    border-radius: 10px;
}


.scrollable-height .table-responsive::-webkit-scrollbar-thumb:hover {
    background: #03354f;
}

//added to solve button alignment issue
.table-responsive .table td,
.table-responsive .table th {
    vertical-align: middle !important;
}

.btn-lnk-user {
    width: 155px;

    @media (max-width: 575px) {
        width: 127px;
        font-size: .7rem;
    }
}

.emt_usr_dtls_wd {
    width: 350px;

    @media (max-width: 575px) {
        width: 100%;
    }
}

// .bg-konnektive_favicon {
//     width: 32px; height: 32px;
//     background: url('./assets/img/avatars/css_sprites.png') -10px -10px;
// }

// .bg-shopify_favicon {
//     width: 32px; height: 32px;
//     background: url('./assets/img/avatars/css_sprites.png') -62px -10px;
// }

// .bg-sticky_favicon {
//     width: 32px; height: 32px;
//     background: url('./assets/img/avatars/css_sprites.png') -10px -62px;
// }

// .bg-sublytics_favicon {
//     width: 32px; height: 32px;
//     background: url('./assets/img/avatars/css_sprites.png') -62px -62px;
// }
.bg-sticky_favicon {
    width: 24px;
    height: 28px;
    background: url('./assets/img/avatars/css_sprites.png') -66px -29px;
    background-size: 264%;
}

.bg-shopify_favicon {
    width: 24px;
    height: 28px;
    background: url('./assets/img/avatars/css_sprites.png') -98px 3px;
    background-size: 264%;
}

.bg-sublytics_favicon {
    width: 34px;
    height: 30px;
    background: url('./assets/img/avatars/css_sprites.png') -97px -29px;
    background-size: 189%;
}

.bg-konnektive_favicon {
    width: 25px;
    height: 29px;
    background: url('./assets/img/avatars/css_sprites.png') -6px -3px;
    background-size: 264%;
}

.bg-29next_favicon {
    width: 25px;
    height: 29px;
    background: url('./assets/img/avatars/css_sprites.png') -6px 31px;
    background-size: 277%;
}

.status-icon {
    font-size: 15px;
}

.read {
    background: #d9e5eb;
}

.input-group>.input-group-append:not(:last-child)>.input-group-text {
    border-bottom-right-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
}

// .input-group>.input-group-append>.input-group-text{
//     border-bottom-left-radius: 0.25rem !important;
//     border-top-left-radius: 0.25rem !important;
// }

.lin-ht-custom {
    line-height: 1.3;
}

.sidebar .sidebar-minimizer {
    flex: 0 0 48px !important;
}

h1.login-hd {
    margin-left: -2px;
}

.log-in-page .icon-user,
.log-in-page .icon-lock {
    margin-top: 3px;
    font-size: 0.78rem;
}

.row-name {
    line-height: 0.6;
    margin-top: 4px;
}

.input-group-append {
    margin-left: 0px !important;
}

.store-filter {
    .react-autosuggest__suggestions-container--open {
        max-height: 200px !important;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: #063f5d;
            border-radius: 10px;
        }
    }

    .react-autosuggest__suggestion {
        font-size: 13px;
        padding: 5px 10px;
    }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1290px) {
    .all-oreder-head .form-control.col-xl-2 {
        flex: 0 0 14.666667%;
        max-width: 14.666667%;
    }
}



.email {
    width: 100%;
}


.input-group>.form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.store_list_outer .card-group {
    max-height: 70vh;
}

.app-header .nav-item .nav-link .badge {
    left: 16% !important;
}

.footer-pagination select {
    width: 43px;
    border: #c8ced3 1px solid;
    border-radius: 0.2rem;
    height: 25px;
    color: #5c6873;
    display: inline-block;
    text-align: left;
    padding: 0px 0 1px 0;
    margin: 0 0px 0 4px;
}

.ft-select-page {
    border: #c8ced3 1px solid;
    border-left: none;
    border-right: none;
    height: 25px;
    font-size: 12px;
    width: 53px;
    padding-left: 4px;
    color: #848484;
    text-align: center;
}

.ft-select-page:focus,
.footer-pagination select:focus {
    border-color: #c8ced3 !important;
    outline: 0 !important;
}

.pagination-sm .page-link {
    padding: 0.09rem 0.5rem 0.17rem 0.5rem !important;
    height: 25px !important;
}

// select:focus{
//     border:inherit !important;
// }


//chip related css
.wrapper {
    background-color: white;
    width: 400px;
    padding: 2rem;
    box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
    border-radius: 0.3rem;
}

.input {
    width: 100%;
    height: 45px;
    padding: 0 1rem;
    margin-top: 1rem;
    box-sizing: border-box;
    font: inherit;
    border-radius: 0.2rem;
    border: 2px solid #d4d5d6;
    color: #565656;
    -webkit-appearance: none;
}

.input:focus {
    border-color: cornflowerblue;
    outline: none;
}

.input.has-error {
    border-color: tomato;
}

.error {
    margin: 0;
    font-size: 90%;
    color: tomato;
}


.tag-item {
    background-color: #d4d5d6;
    display: inline-block;
    font-size: 14px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 4px 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
}


.tag-item>.button {
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0 3px 0 !important;
    margin-top: 3px;
}

//   .flag-ic {
//       width: 22px;
//       /* height: 30px; */
//       margin: 0 0 0 0;
//   }

.flag-ic {
    text-align: center;
    margin: 8px 12px 0 0;
    width: 22px;
    height: 22px;
    //background: #e5e7e8;
    border-radius: 50%;
    font-size: 14px;
    line-height: 22px;
}

.password-toggle .switch {
    height: 24px;
    width: 39px;
}

.password-toggle .slider:before {
    background-color: #fff;
    bottom: 1px;
    height: 21px;
    left: 0px;
    width: 21px;
    -webkit-box-shadow: -5px 0px 9px -3px rgba(0, 0, 0, 55%);
    -moz-box-shadow: -5px 0px 9px -3px rgba(0, 0, 0, 55%);
    box-shadow: -5px 0px 9px -3px rgba(0, 0, 0, 55%);
}

.password-toggle input:checked+.slider:before {
    -webkit-transform: translateX(19px);
    transform: translateX(19px);
}

.right-nav-name .fa-question-circle-o {
    font-size: 17px;
}

.navbar-nav .nav-link .icon-bell {
    font-size: 13px;
}


@media (max-width: 991px) {
    .navbar-nav .nav-link {
        color: #fff;
    }
}

@media (max-width: 575px) {
    .app-header .nav-item {
        min-width: 24px;
    }

    .right-nav-name .fa-question-circle-o {
        font-size: 14px;
        margin-top: -1px;
    }

    .navbar-nav .nav-link .icon-bell {
        font-size: 12px;
    }
}


// faqs
.faqs {
    width: 100%;
    max-width: 100%;
    margin: 31px auto 30px auto;
    padding: 15px;
    background: #fff;
}

.faqs .faq {
    margin: 0px 0 0 0;
    padding: 0px 0px;
    background-color: #FFF;
    border-radius: 0px;
    border-bottom: #d8dbe0 1px solid;
}

.faqs .faq .faq-question {
    position: relative;
    font-size: 16px;
    font-weight: 400;
    padding: 16px 40px 16px 20px;
    cursor: pointer;
    transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
    content: '';
    cursor: 'pointer';
    position: absolute;
    top: 43%;
    right: 15px;
    width: 20px;
    transform: translateY(-22%);
    height: 20px;
    background-image: url('./assets/img/brand/arrow.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    filter: grayscale(91%) contrast(2%) brightness(114%);
    transition: all 0.4s ease-out;
}

.faq-question:focus-within {
    border-color: #998fed 1px solid;
    box-shadow: 0 0 0 0.2rem #998fed;
}

.faqs .faq .faq-answer {
    opacity: 0;
    max-height: 0;
    overflow-y: hidden;
    transition: all 0.4s ease-out;
}

.faqs .faq.open .faq-question {
    margin-bottom: 6px;
}

.faqs .faq.open .faq-question {
    background: #ebe9fb;
    border-bottom: #d8dbe0 1px solid;
    // color: #035e8e;
}

.faqs .faq.open .faq-question::after {
    filter: grayscale(91%) contrast(13%) brightness(73%);
    cursor: 'pointer';
    transform: translateY(-36%) rotate(180deg);
    // filter: hue-rotate(339deg);
}

.faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
    padding: 20px;
    color: rgba(44, 56, 74, 0.95);
    font-size: 16px;
    font-weight: 400;
}

.faq-answer-video video {
    width: 100%;
    height: 500px;
}

.faqs .faq:last-child {
    border-bottom: none;
}

// store bulk action css
.btn-primary.btn-lnk-user {
    border-color: #20a8d8;
    color: #fff;
}

.show>.btn-primary.btn-lnk-user.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(65, 181, 222, 50%);
}

.show>.btn-primary.btn-lnk-user.dropdown-toggle {
    background-color: #1985ac !important;
    border-color: #187da0;
}

.card-cst {
    margin: -15px -15px 18px -15px;
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
}

@media (max-width: 767px) {
    .faq-answer-video {
        video {
            width: 100%;
            height: auto;
        }
    }

    .btn-sm-lnk {
        width: 46px;
        margin-right: 5px;
    }

}

.btn-sm-lnk span {
    display: inline-block;

    @media (max-width: 767px) {
        display: none;
    }

}

.card-cst .card-body {
    padding: 15px;
}

.modal-cst {
    top: 15%;
}

.modal-ccst {
    top: 25%;
}

.postage-tbl .table td:nth-child(2),
.postage-tbl .table th:nth-child(2) {
    text-align: center;
}

.form-control option[disabled] {
    background: #f1efef;
}

.file-input {
    display: flex;
    // position:absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 400px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .04);

    border: 0px dashed rgb(117, 112, 112);
    padding: 0.75em !important;
}

.upload-input {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    height: auto !important;
    padding: 0.75em !important;
}

.upload-icon {
    height: auto !important;
    width: 100px !important;
    margin: 10px;
}

// .file-input input[type=file]::file-selector-button{
//     display:none;
//     }
.drop-title {
    color: #444;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    transition: color .2s ease-in-out;
}

.csv-alert {
    margin-top: 26px;
    font-weight: bold;
    text-align: center;
}

.btnInput {
    text-decoration: none;
    background-color: #acc3eb;
    color: #ffffff;
    padding: 10px 20px;
    border: none;
    outline: none;
    transition: 0.3s;
}

.btnInput:hover {
    text-decoration: none;
    background-color: #ffffff;
    color: #9fbae7;
    padding: 10px 20px;
    border: none;
    outline: 0px solid #010101;
}

.card h3 {
    font-size: 22px;
    font-weight: 600;

}

.drop_box {
    margin: 10px 0;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px dotted #a3a3a3;
    border-radius: 5px;
}

.drop_box h4 {
    font-size: 16px;
    font-weight: 400;
    color: #2e2e2e;
}

.drop_box p {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 12px;
    color: #a3a3a3;
}

.btnInput {
    text-decoration: none;
    background-color: #6ea1f8;
    color: #ffffff;
    padding: 10px 20px;
    transition: 0.3s;
}

.btnInput:hover {
    text-decoration: none;
    background-color: #ffffff;
    color: #005af0;
    padding: 10px 20px;
    border: none;
    outline: 1px solid #010101;
}

.form input {
    margin: 10px 0;
    width: 100%;
    background-color: #e2e2e2;
    border: none;
    outline: none;
    padding: 12px 20px;
    border-radius: 4px;
}

//      .card-design {
//     border-radius: 10px;
//     box-shadow: 0 5px 10px 0 rgba(59, 58, 58, 0.3);
//     width: 500px;
//     height: auto;
//     background-color: #ffffff;
//     padding: 20px 30px 30px;
// }
.storesearch {
    .store-filter {
        padding: 0px;
        width: 100% !important;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

.asset-card:hover {
    background-color: #aaa;
}

.panel {
    border: none;
    box-shadow: none;
}

.panel-heading {
    border-color: #e6e6e6;
    font-size: 16px;
    font-weight: 300;
}

.panel-title {
    color: #2A3542;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
    margin-top: 0;
    font-family: 'Open Sans', sans-serif;
}

/*product list*/

.prod-cat li a {
    border-bottom: 1px dashed #d9d9d9;
}

.prod-cat li a {
    color: #3b3b3b;
}

.prod-cat li ul {
    margin-left: 30px;
}

.prod-cat li ul li a {
    border-bottom: none;
}

.prod-cat li ul li a:hover,
.prod-cat li ul li a:focus,
.prod-cat li ul li.active a,
.prod-cat li a:hover,
.prod-cat li a:focus,
.prod-cat li a.active {
    background: none;
    color: #ff7261;
}

.pro-lab {
    margin-right: 20px;
    font-weight: normal;
}

.pro-sort {
    padding-right: 20px;
    float: left;
}

.pro-page-list {
    margin: 5px 0 0 0;
}

.product-list img {
    width: 100%;
    border-radius: 4px 4px 0 0;
    -webkit-border-radius: 4px 4px 0 0;
}

.product-list .pro-img-box {
    position: relative;
}

.adtocart {
    background: #fc5959;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    color: #fff;
    display: inline-block;
    text-align: center;
    border: 3px solid #fff;
    left: 45%;
    bottom: -25px;
    position: absolute;
}

.adtocart i {
    color: #fff;
    font-size: 25px;
    line-height: 42px;
}

.pro-title {
    color: #5A5A5A;
    display: inline-block;
    margin-top: 20px;
    font-size: 16px;
}

.product-list .price {
    color: #fc5959;
    font-size: 15px;
}

.pro-img-details {
    margin-left: -15px;
}

.pro-img-details img {
    width: 100%;
}

.pro-d-title {
    font-size: 16px;
    margin-top: 0;
}

.product_meta {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 10px 0;
    margin: 15px 0;
}

.product_meta span {
    display: block;
    margin-bottom: 10px;
}

.product_meta a,
.pro-price {
    color: #000;
}

.pro-price,
.amount-old {
    font-size: 18px;
    padding: 0 10px;
}

.amount-old {
    text-decoration: line-through;
}

.quantity {
    width: 120px;
}

.pro-img-list {
    margin: 10px 0 0 -15px;
    width: 100%;
    display: inline-block;
}

.pro-img-list a {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
}

.pro-d-head {
    font-size: 18px;
    font-weight: 300;
}

.assets-video {
    height: 100%;
    width: 100%;
    padding: 0px 10px 20px;
    background: #fcf9f9;
}

.product-suggestion {
    border: 1px solid #aaa;
    border-top: none;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 100%;
    overflow-y: scroll;
    max-height: 200px;
    background: white;
    position: absolute;
}

.product-suggestion-list {

    width: 100%;
    text-align: left;
}

.product-suggestion-list :hover {
    background-color: #E4E5E6;
}

.product-suggestion-list ul {
    margin-bottom: 0px;
    padding: 7px 15px;
    -webkit-padding-start: 0;
    padding-inline-start: 15px;
}

.img-wrapper {
    overflow: hidden;
}

.hover-zoom img:hover {
    max-width: -webkit-fill-available;
    transform: scale(1.25);
}

.custom-badges {
    width: fit-content;
    padding: 5px;

}

.audio-div {
    margin-top: 40px;
}

span.tagged_as {
    position: relative;
}

.custom-badges-2 {
    line-height: 16px;
}

.img-wrapper img {
    max-width: 100%;
    transition: all 0.3s;
    display: block;
    width: 100%;
    height: auto;
    transform: scale(1);
}

.img-wrapper img:hover {
    transform: scale(1.3);
}

.p-custom {
    padding: 0 0 0 1.2rem;
}

@media (max-width: 767px) {
    .p-custom {
        padding: 0 0 0 0rem;
    }
}
.downlist-img {
    width: 80px;
    object-fit: contain;
    height: 72px;
}
.download-type-name {
    max-width: 187px;
}
.download-type-name-total {
    word-break: break-all;
}
.inputs {
    width: 100%;
    height: 45px;
    padding: 0 1rem;
    margin-top: 1rem;
    box-sizing: border-box;
    font: inherit;
    border-radius: 0.2rem;
    border: 1px solid #d4d5d6;
    color: #565656;
    -webkit-appearance: none;
}

.inputs:focus {
    border-color: cornflowerblue;
    outline: none;
}

.inputs.has-error {
    border-color: tomato;
}

.error {
    margin: 0;
    font-size: 90%;
    color: tomato;
}
.w-100{
    width:100%
}
.bulk-dwn-tbl table tr td:first-child {
    width: 5%;
}
.swal2-input {
    border: 2px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
  }
  .swal2-confirm {
    background-color: #4CAF50;
    
    color: white;
    border-radius: 4px;
    padding: 12px 24px;
    font-size: 16px;
    margin-right: 16px;
  }
  .swal2-confirm[disabled]{
    cursor: auto !important;
    background-color:#ccc !important;
    color: white;
   
   
  }
  
  .swal2-cancel {
    background-color: #f44336;
    color: white;
    border-radius: 4px;
    padding: 12px 24px;
    font-size: 16px;
  }
  .swal2-cancel[disabled]{
    cursor: auto !important;
    background-color:#ccc !important;
    color: white;
  }
  
  .swal2-styled:focus {
    outline: none;
  }
  
  .swal2-styled:hover {
    cursor: pointer ;
    opacity: 0.8 ;;
  }
  

.react-tel-input .form-control {
    border: 1px solid #e4e7ea !important ;
    width: 100% !important ;

}
.border-none {
  border: none;
}
/* In YourComponent.css */

.login-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .or-text {
    margin: 10px 0;
    text-align: center;
    font-weight: bold;
  }
  
  .back-button {
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
  .cust-dropdwn{
    overflow-y: auto;
    max-height: 350px;
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #063f5d;
        border-radius: 10px;
    }
  }
  
  .css-13cymwt-control{
    min-height: 35px !important;
    margin: 0 3px 0 0;
    border-color: #e4e7ea !important;
  }
  .css-1xc3v61-indicatorContainer{ padding : 6px !important;
}
.rdw-editor-main{
    border:1px solid #d4d5d6 !important;
    min-height: 170px !important;
}
.rdw-embedded-wrapper{
    display: none !important;
}
.rdw-editor-wrapper{

   border: 1px solid #d4d5d6 !important
}
.rdw-link-modal{
    height: 263px !important;
}
.orderCountDiv{
    min-height: 120px !important
}
.description-list ul {
    padding-left: 18px;
}
.description-list ol {
    padding-left: 18px;
}
.disabled-link {
  color: grey;
  cursor: not-allowed;
  position: relative;
}

.disabled-link:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px;
  border-radius: 3px;
  white-space: nowrap;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.2s;
}

.disabled-link:hover::after {
  opacity: 1;
}
.custom-modal-edit {
    max-height: 500px; /* Adjust the height as needed */
    overflow-y: auto;
  }
  .modal{
    background: rgba(0, 0, 0, 0.8);
  }
  .form-check.view:first-child {
    padding-left:0px !important;
    }
    .modal-header{
padding-bottom: 0;
padding-left: 1;
    }
    .modal-body{
        padding-top: 0;
    }

    